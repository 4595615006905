export function formatNumber(number: number): string {
  return new Intl.NumberFormat("cs-CZ").format(number);
}

/**
 * Make sure min is always lesser than max (or equal).
 * Meaning if min > max, this method returns [ max, min ].
 * If min or max is undefined do not make any changes.
 */
type RangeBoundary = number | undefined;
export function forceAscRangeOrder(min: RangeBoundary, max: RangeBoundary): [RangeBoundary, RangeBoundary] {
  if (min !== undefined && max !== undefined) {
    return min < max ? [min, max] : [max, min];
  } else {
    return [min, max];
  }
}

export enum Events {
  showTextOverlay,
  hideTextOverlay,

  toastSuccess,
  toastError,
  openGallery,

  collectionUpdated,
  collectionParamsChanged,
  collectionParamsSynchronize,

  dropdownOpen,
  keyEscape,
  confirm,

  userUpdated,

  updateContentWidth,
}

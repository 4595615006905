import { BuildMode } from "@/types";

export enum EventName {
  RegistrationDone = "registration-done",
  ItemAdded = "item-added",
  ItemDeleted = "item-deleted",
}

export type Event = {
  name: EventName;
  // Add params when needed
};

const mode = import.meta.env.MODE;

export function trackEvent(event: Event): void {
  if (mode !== BuildMode.production) {
    return; // Track only in production
  }

  if (typeof window.umami === "undefined") {
    throw new Error("DEV: Should not happen. Umami not found.");
  }

  window.umami.track(event.name /* add event params as second argument when needed */);
}

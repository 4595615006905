import { ref, onMounted, onUnmounted, computed } from "vue";

const DesktopMinScreenWidth = 1200;
const TabletMaxScreenWidth = 768;
const PhoneMaxScreenWidth = 576;

export function useScreenSize() {
  const screenWidth = ref(document.documentElement.clientWidth);
  const isDesktop = computed(() => screenWidth.value > DesktopMinScreenWidth);
  const isTablet = computed(() => screenWidth.value <= TabletMaxScreenWidth && screenWidth.value > PhoneMaxScreenWidth);
  const isPhone = computed(() => screenWidth.value <= PhoneMaxScreenWidth);

  onMounted(() => window.addEventListener("resize", updateScreenWidth));
  onUnmounted(() => window.removeEventListener("resize", updateScreenWidth));

  const updateScreenWidth = () => (screenWidth.value = document.documentElement.clientWidth);

  return {
    isDesktop,
    isTablet,
    isPhone,
  };
}

/**
 * Shared catalogue data.
 */

import { WatchParameter } from "@/services/repositories/database/watch";
import { RawImageData } from "./image";
import { CatalogueSearchItem } from "@/services/repositories/database";

export interface RawCataloguePriceData {
  price: number;
  date: string;
}

export enum CatalogueFilterFieldType {
  userInput,
  dropdown,
}

/**
 * Lego minifigs.
 */

export interface RawLegoMinifigData {
  id: number;
  images?: RawImageData[];
  name: string;
  number: string;
  partsCount: number | null;
  prices?: RawCataloguePriceData[];
}

/**
 * Lego sets.
 */

export interface RawLegoSetData {
  id: number;
  number: string;
  name: string;
  year: number;
  theme?: RawLegoThemeData;
  partsCount: number;
  weight: number | null;
  dimensions: string | null;
  lastPrice: RawCataloguePriceData;
  prices?: RawCataloguePriceData[];
  images?: RawImageData[];
}

export interface RawLegoThemeData {
  id: number;
  name: string;
}

/**
 * Watches.
 */

export interface RawWatchData {
  id: number;
  images?: RawImageData[];
  name: string;
  parameters: WatchParameterValueRelationRawData[];
}

export interface WatchParameterValueRelationRawData {
  option: RawWatchParameterOptionData;
  parameter: RawWatchParameterData;
}

export interface WatchParameterValueRelation {
  option: RawWatchParameterOptionData;
  parameter: WatchParameter;
}

export interface RawWatchParameterData {
  id: number;
  allowCustomValues: 0 | 1;
  label: string;
  showInDropdown: 0 | 1;
  options?: []; // @todo RawWatchParameterOptionData ?
}

export interface RawWatchParameterOptionData {
  id: number;
  value: string;
}

export interface CatalogueWatchFilterApiData {
  use_in_filter?: 0 | 1;
  loadOptions?: boolean;
  order?: string;
}

export type WatchFilterApiData = {
  page: number;
  perPage: number;
  filter: ({ parameter: string; q: DatabaseFilterParameterValue } | { parameterId: string | number; optionId: DatabaseFilterParameterValue })[];
};

export const WATCH_REF_NUMBER_PARAMETER_ID = 31;
export const WATCH_MODEL_PARAMETER_ID = 30;
export const WATCH_BRAND_PARAMETER_ID = 29;

export interface WatchSearchResult {
  type: "fromDatabase" | "custom";
  name?: string;
  id?: number;
}

/**
 * Database history cache ( = filter + pagination).
 */

export type DatabaseFilterParameterValue = string | number | null;

export interface DatabaseFilterChangeEvent {
  value: number | null;
  name: string;
}

// New general filter interfaces (flexible storage preparation):

export const VinylsFilterYearFrom = 1917;
export const VinylsFilterYearTo = new Date().getFullYear();

export interface CatalogueFilterDropdownOption {
  label: string | number;
  value: string | number;
}

/**
 * Shared database API data.
 */

export interface RawAutocompleteData {
  id: number;
  name: string;
  identification: string;
  images: RawImageData[];
}

export interface RawCatalogueItemData {
  // Specific item detail (item card).
  id: number;
  name: string;
  images: RawImageData[];
  parameters: RawCatalogueItemParameter[];
}

export interface RawCatalogueItemParameter {
  option: {
    value: string;
  };
  parameter: {
    id?: number;
    label: string;
    isInfoPrice?: true;
    isWidgetIdentificator?: true;
  };
}

export const IsInfoPriceFlag = "isInfoPrice";
export const IsWidgetIdentificator = "isWidgetIdentificator";

export type CatalogueFilterParams = {
  // Sent to API
  categoryId: number;
  page: number;
  perPage: number;
  [key: string]: DatabaseFilterParameterValue;
};

export interface CatalogueSearchData {
  totalCount: number;
  items: CatalogueSearchItem[];
}

export interface CatalogueSearchResponse {
  // List of items in table, not specific item detail.
  totalCount: number;
  collectibles: SearchedItemRawData[];
}

export type SearchedItemRawData = RawCatalogueSearchedItemAlwaysPresent & (VinylsTableParams | BoardGamesTableParams);

interface RawCatalogueSearchedItemAlwaysPresent {
  id: number;
  name: string;
  images: RawImageData[];
  categoryId: number;
}

interface VinylsTableParams {
  catNumber: string;
  price: string;
  interpret: string;
  year: string;
}

interface BoardGamesTableParams {
  author: string;
  language: string;
  year: string;
}

export interface CatalogueTableColumn {
  label: string;
  key: string;
  isPrice?: true;
  align?: "left" | "right" | "center";
}

import { translate } from "@/services/translation";
import { Layout } from "@/types";

export enum ErrorRouteName {
  error404 = "error404",
}

export const errorPagesRoutes = [
  {
    name: ErrorRouteName.error404,
    path: "/:pathMatch(.*)",
    components: { mainContent: () => import("@/views/error/NotFound404.vue") },
    meta: {
      public: true,
      title: translate("views.error404.title"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
];

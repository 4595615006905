import { ref } from "vue";

export interface PaginationComposableOptions {
  itemsCount: number;
  onGoTo?: (i: number) => void;
  onNext?: ((i: number) => void) | (() => void);
  onPrevious?: ((i: number) => void) | (() => void);
  currentPage?: number;
}

export function usePagination(options: PaginationComposableOptions) {
  const currentPage = ref<number>(options.currentPage ?? 0);

  function goTo(i: number): void {
    currentPage.value = i;

    if (options.onGoTo) {
      options.onGoTo(i);
    }
  }

  function next(): void {
    if (currentPage.value === options.itemsCount - 1) {
      currentPage.value = 0;
    } else {
      currentPage.value++;
    }

    options.onNext ? options.onNext(currentPage.value) : null;
  }

  function previous(): void {
    if (currentPage.value === 0) {
      currentPage.value = options.itemsCount - 1;
    } else {
      currentPage.value--;
    }

    options.onPrevious ? options.onPrevious(currentPage.value) : null;
  }

  const updateItemsCount = (newCount: number) => (options.itemsCount = newCount);

  return {
    currentPage,
    goTo,
    previous,
    next,
    updateItemsCount,
  };
}

import addItem from "./views/addItem";
import contacts from "./views/contacts";
import ebay from "./views/ebay";
import userSettings from "./views/userSettings";
import publicHomepage from "./views/publicHomepage";
import itemDetail from "./views/itemDetail";
import legalInformation from "./views/legalInformation";
import magazine from "./views/magazine";
import nicknameForm from "./views/profileInitialization";
import general from "./general/general";
import form from "./general/form";
import appLayout from "./general/appLayout";
import publicLayout from "./general/publicLayout";
import collection from "./general/collection";
import catalogue from "./views/catalogue";
import publicItemsList from "./views/publicItemsList";
import publicItemCard from "./components/publicItemCard";
import dashboard from "./views/dashboard";
import myCollection from "./views/myCollection";
import chat from "./views/chat";
import termsAndConditions from "./views/termsAndConditions";
import itemLike from "./components/itemLike";
import auth from "./views/auth";
import publicItemsFilter from "./components/publicItemsFilter";
import profileMy from "./views/profileMy";
import profilePublic from "./views/profilePublic";
import error404 from "./views/error404";

export default {
  ...publicLayout,
  ...appLayout,
  ...form,
  ...general,
  ...collection,
  views: {
    ...addItem,
    ...auth,
    ...catalogue,
    ...contacts,
    ...dashboard,
    ...ebay,
    ...error404,
    ...chat,
    ...itemDetail,
    ...legalInformation,
    ...myCollection,
    ...nicknameForm,
    ...magazine,
    ...profileMy,
    ...profilePublic,
    ...publicHomepage,
    ...publicItemsList,
    ...userSettings,
    ...termsAndConditions,
  },
  components: {
    ...itemLike,
    ...publicItemCard,
    ...publicItemsFilter,
  },
};

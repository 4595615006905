export default {
  publicItemsList: {
    title: "Předměty sběratelů",
    heading: "Předměty sběratelů",
    filter: {
      heading: "Filtr",
      toggleBtn: "Filtr",
      showBtn: "Nic nenalezeno | Zobrazit {count} předmět | Zobrazit {count} předměty | Zobrazit {count} předmětů",
      showBtnLoading: "Vyhledávám předměty…",
    },
    grid: {
      emptyMsg: "Bohužel, tvému filtru neodpovídá žádná položka. Zkus vyhledávání upravit, třeba se tu tvůj vybraný kousek někde schovává.",
      errorMsg: "Ops. Chyba vyhledávání.",
      errorMsgBtn: "Obnovit stránku",
    },
    favouriteCategories: {
      noSelected: {
        text: "@:views.dashboard.publicItems.forYou.popupText",
        cta: "@:views.dashboard.publicItems.forYou.popupBtn",
      },
      nothingFound: {
        text: "@:views.dashboard.publicItems.forYou.emptyMsg",
        cta: "@:views.dashboard.publicItems.forYou.emptyBtn",
      },
      infoText: "@:views.dashboard.publicItems.forYou.infoText",
    },
  },
};

export default {
  error404: {
    title: "@:views.error404.heading",
    heading: "Oops! Zabloudil jsi…",
    text: "Vypadá to, že jsi narazil na tajemnou stránku, kterou ani internetový skřítek nezná. A to už je co říct, protože ten ví skoro všechno!",
    ctaPrimary: "Zpět domů",
    ctaSecondary: "Předměty sběratelů",
    footerText: "V případě přetrvávajích potíží nás neváhej kontaktovat na",
  },
};

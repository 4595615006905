export default {
  profilePublic: {
    sendMessage: "@:views.itemDetail.actions.sendMessage",
    noContact: "Uživatel nemá vyplněné osobní údaje.",
    notFound: {
      doesNotExists: "Sbírka neexistuje",
      heading: "Jak funguje Colectio",
      point1: "Měj svou sbírku na jednom místě",
      point2: "Sdílej své předměty a prodávej",
      point3: "Využívej aktuálních trendů a dat z trhu",
      point4: "Sleduj vývoj cen předmětů",
      point5: "Užij si magazín ze světa sběratelů",
      bottomBarText: "Chceš taky <br />svoji <strong>sbírku?</strong>",
      bottomBarCta: "Ano, chci!",
    },
  },
};

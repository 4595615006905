<template>
  <ElTag v-bind="attrs" class="el-tag colectio-tag">
    <slot></slot>
  </ElTag>
</template>

<script setup lang="ts">
import { ElTag } from "element-plus";
import { useAttrs } from "vue";

const attrs = useAttrs();
</script>

<style lang="scss">
.el-tag.colectio-tag {
  --verticalPadding: calc(var(--spacing1x) / 2);
  --horizontalPadding: var(--spacing3x);

  padding: var(--verticalPadding) var(--horizontalPadding);
  color: var(--white);
  text-transform: uppercase;
  font-size: 11px;
  border-radius: var(--radius4x);
  background-color: var(--grayTransparent500);
  border: 0;
  height: 22px;
  line-height: 0.5;
}
</style>

export default {
  myCollection: {
    heading: "Moje sbírka",
    empty: {
      hi: "Ahoj",
      text: "Zatím nemáš ve své sbírce žádný předmět",
      cta: "Přidat předmět",
    },
  },
};

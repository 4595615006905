import { thirdPartyLogin, establishUserForThisSession } from "@/services/user/userAuthentication";
import { Events } from "@/types";
import { googleSdkLoaded, googleLogout, type CallbackTypes } from "vue3-google-login";
import { AccountType, LoginProvider, ThirdPartyLoginStatus } from "./internalUserTypes";
import { createProfileInitializationRouteLink } from "@/utilities";
import { router } from "@/router";
import { UserNotEstablishedException } from "./userExceptions";
import { trackNewRegistrationEvent } from "../marketingHelpers";
import { redirectAfterLogin } from "./loginHelpers";

const GoogleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const GoogleNotDisplayedReasonNoSession = "opt_out_or_no_session";
let subscribeNewsletter = false;

export function login(registerNewsletter: boolean): void {
  subscribeNewsletter = registerNewsletter;
  initGoogle();
  openWindow();
}

export function logout(): void {
  googleLogout();
}

function initGoogle(): void {
  googleSdkLoaded((google) => {
    google.accounts.id.initialize({
      client_id: GoogleClientId,
      cancel_on_tap_outside: false,
      callback: handleLogin,
    });
  });
}

function openWindow(): void {
  googleSdkLoaded((google) => {
    google.accounts.id.prompt((notification: google.accounts.id.PromptMomentNotification) => {
      const notDisplayedReason = notification.getNotDisplayedReason();

      if (notDisplayedReason === GoogleNotDisplayedReasonNoSession) {
        window.eventBus.emit(Events.toastError, "Nepodařilo se vytvořit Google přihlášení. Máte povoleno vytváření cookies?");
      } else if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        google.accounts.id.prompt();
      }
    });
  });
}

async function handleLogin(response: CallbackTypes.CredentialPopupResponse): Promise<void> {
  window.eventBus.emit(Events.showTextOverlay, { text: "Kontroluji údaje..." });

  const token = response.credential;

  const loginResponse = await thirdPartyLogin(token, subscribeNewsletter, LoginProvider.google);
  window.eventBus.emit(Events.hideTextOverlay);

  if (loginResponse.status === ThirdPartyLoginStatus.ok) {
    const { isFirstLogin, user, token } = loginResponse;
    if (isFirstLogin) {
      trackNewRegistrationEvent(user.id, AccountType.google);
    }

    establishUserForThisSession(token, user)
      .then(() => redirectAfterLogin())
      .catch((e: unknown) => {
        if (e instanceof UserNotEstablishedException) {
          const redirect = createProfileInitializationRouteLink();
          router.push(redirect);
        } else {
          throw e;
        }
      });
  } else {
    window.eventBus.emit(Events.toastError, loginResponse.message);
  }
}

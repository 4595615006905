import { AccountType } from "./user/internalUserTypes";
import * as GoogleAnalytics from "@/services/googleAnalytics";
import * as FacebookPixel from "@/services/facebookPixel";
import * as Umami from "@/services/umami";

export function trackNewRegistrationEvent(userId: number, accountType: AccountType): void {
  const gaEvent = new GoogleAnalytics.AccountVerifiedEvent(userId, accountType);
  GoogleAnalytics.trackEvent(gaEvent);

  const fbEvent = FacebookPixel.FacebookPixelEvent.CompleteRegistration;
  FacebookPixel.trackEvent(fbEvent);

  const umamiEvent = { name: Umami.EventName.RegistrationDone };
  Umami.trackEvent(umamiEvent);
}

import { RouteLocationNormalized, createRouter, createWebHistory } from "vue-router";
import { TokenNotValidException, User, checkUserOnInitialPageLoad, getUser as getRunningAuthentication, wasSomeoneEverLoggedIn } from "@/services/user";
import { wrapPrepositions, createLoginRouteLink, createRegistrationRouteLink, isPublic, setTitle, storePreviousPage } from "@/utilities";
import { PublicRouteName, publicRoutes } from "./publicRoutes";
import { AppRouteName, appRoutes } from "./appRoutes";
import { ErrorRouteName, errorPagesRoutes } from "./errorRoutes";
import { store } from "@/store";

export const RouteName = { ...PublicRouteName, ...AppRouteName, ...ErrorRouteName };

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkExactActiveClass: "active",
  routes: [...publicRoutes, ...appRoutes, ...errorPagesRoutes],
  scrollBehavior(to, from) {
    if (to.hash && document.getElementById(to.hash.slice(1))) {
      return { el: to.hash };
    }

    if (to.name === from.name && to.meta.preventScrollToTop === true) {
      return;
    }

    return { top: 0 };
  },
});

class LoginNeededException extends Error {}

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
  storePreviousPage(from);
  const isRoutePublic = isPublic(to);
  updatePageTitle(to);

  try {
    const user = await getUser();
    if (isRoutePublic) {
      const redirectWhenLoggedIn = to.meta.redirectWhenLoggedIn;
      if (redirectWhenLoggedIn && user) {
        return next(redirectWhenLoggedIn);
      } else {
        return next();
      }
    } else {
      if (user) {
        return next();
      } else {
        throw new LoginNeededException();
      }
    }
  } catch (e: unknown) {
    const isTokenInvalid = e instanceof TokenNotValidException;
    if (isTokenInvalid && isRoutePublic) {
      return next();
    } else if (isTokenInvalid || e instanceof LoginNeededException) {
      if (wasSomeoneEverLoggedIn()) {
        const redirect = to.fullPath;
        return next(createLoginRouteLink(redirect));
      } else {
        return next(createRegistrationRouteLink());
      }
    } else {
      throw e;
    }
  }
});

router.afterEach((to: RouteLocationNormalized) => {
  wrapPrepositions();
  updateBreadcrumbs(to);
});

const getUser = async (): Promise<User | undefined> => {
  const user = await getRunningAuthentication();
  // no currently running authentication process or no token found
  if (user === undefined) {
    const newlyAuthenticatedUser = await checkUserOnInitialPageLoad(); // check if there is any token stored and possibly start authentication
    return newlyAuthenticatedUser === null ? undefined : newlyAuthenticatedUser;
  } else {
    return user;
  }
};

function updatePageTitle(route: RouteLocationNormalized): void {
  const title: string = route.meta.title as string;
  setTitle(title);
}

function updateBreadcrumbs(route: RouteLocationNormalized): void {
  const breadcrumbs = route.meta.breadcrumbs;
  if (breadcrumbs) {
    store.commit("setBreadcrumbs", breadcrumbs);
  } else {
    store.commit("clearBreadcrumbs");
  }
}

export { router };

<template>
  <ElButton :tag="tag" v-bind="attrs">
    <slot></slot>
  </ElButton>
</template>

<script setup lang="ts">
import { ElButton } from "element-plus";
import { computed, useAttrs } from "vue";

const attrs = useAttrs();

const tag = computed(() => {
  if (attrs.to) {
    return "RouterLink";
  } else if (attrs.href) {
    return "a";
  } else {
    return "button";
  }
});
</script>

export default {
  profileMy: {
    heading: "@:appLayout.topbar.user.settingsBtn",
    settingsBtn: "@:views.userSettings.heading",
    noContact: "@:views.profilePublic.noContact",
    sharePanel: {
      heading: "Sdílej svoji sbírku",
      toggleLabel: "Věřejný profil",
      textPublic: "Zkopíruj si odkaz na svůj profil a sdílej ho s ostatními sběrateli. Využít ho můžeš například v prodejních skupinách nebo jako prezentaci své sbírky.",
      textPrivate: "Pokud chceš svoji sbírku sdílet, musí být nejdříve ve stavu “<strong>veřejný</strong>”.",
      linkCopiedMsg: "Hurá! Odkaz na tvůj profil zkopírován do schránky. Sdílej svoji sbírku. :)",
      openPofileBtn: "Zobrazit veřejný profil",
    },
    items: {
      heading: "Zveřejněné předměty",
      noItems: "Bohužel, tvému filtru neodpovídá žádná položka. Zkus vyhledávání upravit, třeba se tu tvůj vybraný kousek někde schovává.",
      offeringFor: "Nabízím za",
      filter: {
        categoryPlaceholder: "Všechny kategorie",
      },
    },
  },
};
